













































import CreateHotelClassificationRequestViewModel from '@/src/services/viewModel/resource/CreateHotelClassificationRequestViewModel';
import Vue from 'vue';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    costEntriesMember: [
      {
        caption: Vue.$t('page.classifyHotel.table.baseContribution'),
        oneStar: 100.00,
        twoStar: 150.00,
        threeStar: 200.00,
        fourStar: 250.00,
        fiveStar: 300.00,
      },
      {
        caption: Vue.$t('page.classifyHotel.table.roomContribution'),
        oneStar: 3.00,
        twoStar: 3.00,
        threeStar: 4.00,
        fourStar: 5.00,
        fiveStar: 5.00,
      },
    ],
    costEntriesNonMember: [
      {
        caption: Vue.$t('page.classifyHotel.table.baseContribution'),
        oneStar: 200.00,
        twoStar: 300.00,
        threeStar: 400.00,
        fourStar: 500.00,
        fiveStar: 600.00,
      },
      {
        caption: Vue.$t('page.classifyHotel.table.roomContribution'),
        oneStar: 3.00,
        twoStar: 3.00,
        threeStar: 4.00,
        fourStar: 5.00,
        fiveStar: 5.00,
      },
    ],
    memberCostColumns: [
      { value: Vue.$t('page.classifyHotel.table.memberCostTitle'), field: 'caption' },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐', field: 'oneStar',
      },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐⭐', field: 'twoStar',
      },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐⭐⭐', field: 'threeStar',
      },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐⭐⭐⭐', field: 'fourStar',
      },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐⭐⭐⭐⭐', field: 'fiveStar',
      },
    ],
    nonMemberCostColumns: [
      { value: Vue.$t('page.classifyHotel.table.nonMemberCostTitle'), field: 'caption' },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐', field: 'oneStar',
      },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐⭐', field: 'twoStar',
      },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐⭐⭐', field: 'threeStar',
      },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐⭐⭐⭐', field: 'fourStar',
      },
      {
        width: '100px', textAlign: 'right', format: (data: number) => Vue.$format.currency(data, 'CHF'), value: '⭐⭐⭐⭐⭐', field: 'fiveStar',
      },
    ],
  }),
  computed: {
    innerValue: {
      get(): CreateHotelClassificationRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateHotelClassificationRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    getMembership() {
      this.$router.push('/gs/memberPortal/member-data');
    },
  },
});
